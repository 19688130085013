
<template>
  <div>
    <img style="width: 100%" src="../../assets/img/zxz12qer.png" alt="" />
    <!-- <img style="width: 100%" src="../../assets/img/zxz34et.png" alt="" /> -->

    <a href="https://www.mrcg.group">
      <img
        style="width: 100%; display: block"
        src="../../assets/img/3a130.png"
        alt=""
      />
    </a>

    <page-footer />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  components: {
    "page-footer": () => import("@/components/footer.vue"),
  },
};
</script>

<style scoped>
</style>
